import { graphql, useStaticQuery } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image";

import Nav from "../../components/Nav"
import SEO from "../../components/SEO"
import Modal from "../../components/Modal"
import Layout from "../../components/Layout"
import DownloadApp from "../../components/DownloadApp"
import GlobalStyle from "../../components/GlobalStyle"

import Analytics from "../../utils/tracking"

const perksData = require("../../data/wallbox-perks.json")

const Perk = ({ title, imageSrc, openModal }) => (
  <PerkContainer>
    <PerkImage src={imageSrc} />

    <PerkTitle>{title}</PerkTitle>

    <PerkButton onClick={openModal}>View Perk</PerkButton>
  </PerkContainer>
)

const WallboxPage = () => {

  const [modalData, setModalData] = useState(null)
  const [isModalOpen, setModalIsOpen] = useState(false)

  useEffect(() => {
    ;(async () => {
      await Analytics.track("Wallbox x Stockperks Landing Page")
    })()
  }, [])

  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "clients/wallbox/cover.jpg" }) {
        childImageSharp {
          fixed(width: 415, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logo: file(relativePath: { eq: "clients/wallbox/logo.png" }) {
        publicURL
      }
    }
  `)

  const onOpenModal = (perkData) => {
    setModalData(perkData)
    setModalIsOpen(true)
  }

  const onCloseModal = () => {
    setModalData(null)
    setModalIsOpen(false)
  }

  return (
    <>
      <Layout>
        <SEO
          title="Stockperks x Wallbox"
          url={"/company/wallbox"}
        />
        <GlobalStyle />
        <Nav siteTitle={"Stockperks x Wallbox"} />

        <PageContent>
          <CoverSection style={{ marginBottom: 65 }}>
            <div>
              <Img
                fixed={data.cover.childImageSharp.fixed}
                style={{ height: 415, borderRadius: 16 }}
              />
            </div>

            <div>
              <ImageContainer>
                <img src={data.logo.publicURL} style={{ maxWidth: 152 }}/>
              </ImageContainer>
              <SymbolTag>NYSE: WBX</SymbolTag>

              <Title>Wallbox is now on Stockperks!</Title>

              <Paragraph>
                Wallbox is a global company, dedicated to changing the way the world uses energy in the electric vehicle industry. Wallbox creates smart charging systems that combine innovative technology with outstanding design and manage the communication between vehicle, grid, building and charger.
              </Paragraph>
            </div>
          </CoverSection>

          <PerkSection style={{ marginBottom: 85 }}>
            <div>
              <TitleSmall>$WBX shareholders are now eligible for {perksData.length} amazing perks</TitleSmall>
            </div>

            <PerksGrid>
              {perksData.map((perk, i) => {
                return (
                  <Perk
                    key={i}
                    title={perk.title}
                    imageSrc={perk.imageSrc}
                    openModal={() => onOpenModal(perk)}
                  />
                )
              })}
            </PerksGrid>
          </PerkSection>

          <Section>
            <DownloadApp
              title="Explore more perks now"
              text="Download the app and start redeeming perks"
            />
          </Section>
        </PageContent>
      </Layout>

      <Modal
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
      >
        <ModalContainer style={{ marginBottom: 50 }}>
          <ModalImage>
            <PerkImage src={modalData?.imageSrc} style={{ maxWidth: 450, height: "16vw", minHeight: 200 }} />
          </ModalImage>

          <ModalContent>
            <ImageContainer>
              <img src={data.logo.publicURL} style={{ maxWidth: 152 }}/>
            </ImageContainer>
            <SymbolTag>NYSE: WBX</SymbolTag>

            <Title>{modalData?.title}</Title>

            <ModalParagraph dangerouslySetInnerHTML={{ __html: modalData?.description }}/>

            <ModalSubtitle style={{ marginTop: 25 }}>Eligibility Rules</ModalSubtitle>
            <ModalParagraph>{modalData?.eligibilityRules}</ModalParagraph>
          </ModalContent>
        </ModalContainer>

        <DownloadApp
          title="Explore more perks now"
          text="Download the app and start redeeming perks"
          isModal={true}
        />
      </Modal>
    </>
  )
}

export default WallboxPage

const PageContent = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  width: 100%;
`

const Section = styled.article`
  display: flex;
  margin: 0 auto;
  max-width: 1100px;
  width: 90vw;
  @media (max-width: 1023px) {
    align-items: center;
    flex-direction: column;
  }
`

const CoverSection = styled(Section)`
  @media(min-width: 1024px) {
    > div:first-child {
      margin-right: 4vw;
    }
  }
`

const PerkSection = styled(Section)`
  @media(min-width: 1024px) {
    > div:first-child {
      margin-right: 2vw;
      min-width: 415px;
    }
  }
`

const SymbolTag = styled.span`
  background-color: #EDEEEE;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  padding: 5px 10px;
`

const Title = styled.h1`
  color: black;
  font-size: 38px;
  line-height: 100%;
  margin: 30px 0;
`

const TitleSmall = styled.h2`
  color: black;
  font-size: 26px;
  line-height: 38px;
  margin: 0;
  width: 80%;
  @media (max-width: 1023px) {
    margin-bottom: 30px;
    width: 100%;
  }
`

const ImageContainer = styled.figure`
  margin: 0 0 5px 0;
`

const Paragraph = styled.p`
  color: black;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
`

const PerksGrid = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  width: 100%;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`

const PerkContainer = styled.div``

const PerkImage = styled.img`
  border-radius: 16px;
  height: 200px;
  object-fit: cover;
  max-width: 360px;
  width: 100%;
  @media (min-width: 1024px) {
    width: 100%;
  }
`

const PerkTitle = styled.h3`
  font-size: 20px;
  line-height: 24px;
  margin: 15px 0;
`

const PerkButton = styled.button`
  background: none;
  border-radius: 4px;
  border: 1px solid #D6DADE;
  color: #202124;
  cursor: pointer;
  font-size: 14px;
  height: 44px;
  outline: none;
  padding: 0 15px;
`

const ModalContainer = styled.div`
  display: grid;
  grid-gap: 5vmin;
  grid-template-columns: 50% 50%;

  @media (max-width: 767px) {
    grid-gap: 25px;
    grid-template-columns: 100%;
  }
`

const ModalContent = styled.div`
  @media(min-width: 768px) {
    grid-row: 1;
  }
`

const ModalImage = styled.div`
  text-align: center;
`

const ModalSubtitle = styled.h4`
  font-size: 16px;
  margin: 0;
`

const ModalParagraph = styled.p`
  color: #5C6268;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0 0 0;
  white-space: pre-wrap;
`
