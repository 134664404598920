import React from "react";
import styled from "styled-components";

import AppStoreLinks from "./AppStoreLinks";

const DownloadAppContainerSelector = ({ isModal, children }) => {
  if (isModal) {
    return (
      <DownloadAppContainerModal>
        {children}
      </DownloadAppContainerModal>
    )
  }

  return (
    <DownloadAppContainer>
      {children}
    </DownloadAppContainer>
  )
}

const DownloadApp = ({ title, text, isModal = false }) => {
  return (
    <DownloadAppContainerSelector isModal={isModal}>
      <TitleDownloadApp>{title}</TitleDownloadApp>

      <ParagraphDownloadApp>
        {text}
      </ParagraphDownloadApp>

      <AppStoreLinks />
    </DownloadAppContainerSelector>
  );
};

export default DownloadApp;

const DownloadAppContainer = styled.div`
  background-color: #E8EDF6;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 100px;
  padding: 2vmax 3vmax;
  width: 100%;
`

const DownloadAppContainerModal = styled(DownloadAppContainer)`
  border-radius: 0 !important;
  left: -10vmin;
  margin: 0 !important;
  padding: 5vmin 10vmin 2vmin 10vmin !important;
  position: relative;
  right: -10vmin;
  width: calc(100% + 20vmin) !important;
`

const TitleDownloadApp = styled.h4`
  color: #1B46A5;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
`

const ParagraphDownloadApp = styled.p`
  color: #1B46A5;
  font-size: 18px;
  line-height: 24px;
  margin: 15px 0 40px 0;
`
