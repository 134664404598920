import React from "react"
import styled from "styled-components"
import ReactModal from "react-modal"

import closeIcon from "../../images/close.svg"

import "./style.css"

ReactModal.setAppElement("body")
ReactModal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, .4)"
ReactModal.defaultStyles.overlay.zIndex = 100

const Modal = ({ isModalOpen, onCloseModal, children }) => (
  <ReactModal
    isOpen={isModalOpen}
    onRequestClose={onCloseModal}
    className="modal"
    overlayClassName="overlay"
  >
    <TopBar>
      <CloseButton onClick={onCloseModal}>
        <img src={closeIcon} alt="Close Icon" />
      </CloseButton>
    </TopBar>

    <ChildrenContainer>
      {children}
    </ChildrenContainer>
  </ReactModal>
)

export default Modal;

const TopBar = styled.div`
  margin-bottom: 15px;
  text-align: right;
`

const CloseButton = styled.button`
  background: none;
  border: 1px solid #D6DADE;
  border-radius: 4px;
  cursor: pointer;
  height: 46px;
  width: 46px;
`

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 5vmin;
`
